<template>
    <div class="pages-body login-page p-d-flex p-flex-column">
        <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
            <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
                <div class="logo">
                    <img src="https://storage.googleapis.com/meraneta_admin/emailerImages/192X192-cpySpU9MhB20240612170826.png"
                        alt="" />
                </div>
            </div>
        </div>
        <div class="p-as-center p-mt-auto p-mb-auto" style="width: 28rem">
            <div class="pages-panel card p-d-flex p-flex-column">
                <div class="pages-header p-px-3 p-py-1">
                    <h2>LOGIN</h2>
                </div>

                <h4>Welcome</h4>
                <div class="p-fluid p-formgrid p-grid" @keydown.enter="submitForm">
                    <div class="input-panel p-d-flex p-flex-column p-px-3">
                        <div class="p-inputgroup p-field">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-mobile"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" maxlength="10" @keypress="validateNumber"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    required="true" v-model="username" />
                                <label for="username">Mobile No</label>
                            </span>
                        </div>
                        <div class="p-inputgroup p-mt-3 p-mb-0">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="password" maxlength="12"
                                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                    required="true" v-model="password" />
                                <label for="password">Password</label>
                            </span>
                        </div>
                        <div class="p-field p-text-left p-mb-0" v-if="submitted && errormsg">
                            <small class="p-invalid p-error">{{ errormsg }}</small>
                        </div>
                        <Button class="login-button p-my-5 p-px-3" v-if="!showLoader" label="LOGIN"
                            @click="login"></Button>
                        <span v-show="showLoader" class="p-my-5"><i class="pi pi-spin pi-spinner indigo-color"
                                style="fontsize: 1.5rem"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../service/ApiService';
import router from '@/router';

export default {
    data() {
        return {
            username: '',
            password: '',
            submitted: false,
            page: false,
            isValid: true,
            userdata: '',
            errormsg: '',
            showLoader: false,
            StorageObjUserRef: localStorage.user_reference,
            StorageObjAccessCode: localStorage.access_code,
        };
    },

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        if (this.StorageObjUserRef) {
            router.push({ path: '/clients' });
        } else if (this.StorageObjAccessCode) {
            router.push({ path: '/login' });
        } else {
            router.push({ path: '/login' });
        }
    },

    methods: {
        goDashboard() {
            router.push({ path: '/clients' });
            window.location.reload();
            //router.push({ path: '/dashboard' });
        },
        forgotPassword() {
            this.page = true;
            router.push({ path: '/forgotpassword' });
        },
        async login() {
            this.showLoader = true;
            this.submitted = true;
            await this.ApiService.userlogin({ login_id: this.username, login_password: this.password }).then((items) => {
                if (items.status === 200) {
                    this.showLoader = false;
                    this.userdata = JSON.stringify(items.data.user_data);
                    localStorage['user_reference'] = items.data.user_reference;
                    localStorage['permission_fks'] = items.data.permission_fks;
                    localStorage['user'] = JSON.stringify(items.data.user_data);
                    localStorage['client_info'] = JSON.stringify(items.data.client_info);
                    localStorage['masterBucketName'] = JSON.stringify(items.data.masterBucketName);
                    this.goDashboard();
                } else {
                    this.showLoader = false;
                    this.errormsg = items.message;
                }
            });
        },
        validateNumber: (event) => {
            let keyCode = event.keyCode;
            if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
            }
        },
        submitForm() {
            this.login();
        },
    },
};
</script>

<style scoped></style>
